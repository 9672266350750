import App from "./App.vue";
import process from "process";
import {createSSRApp} from "vue";
import {createAppRouter} from "#app/router/index.js";
import {setupI18n} from "#app/i18n.js";
import {registerComponents} from "#app/vue-common/index.js";
import {createState as createPageState} from "#app/store/page.js";
import imaskDirective from "#app/utils/imask.js";
import scrollDirective from "#app/utils/scroll.js";
import appearDirective from "#app/utils/appear.js";
import VueGtag from "vue-gtag";
import {initInterceptors} from "#app/net/interceptors.js";
import Cookie from "cookie-universal";
import UAParser from "ua-parser-js";
import {createHead} from "@vueuse/head";
import {getRandomCat} from "@/utils/cats.js";
import * as Sentry from "@sentry/vue";

export async function createApp ($ = {}, ssrContext) {
	$.app = createSSRApp(App);

	$.app.directive("imask", imaskDirective);
	$.app.directive("scroll", scrollDirective);
	$.app.directive("appear", appearDirective);

	$.app.provide("initialState", $?.context?.initialState ?? {});
	$.app.provide("globalContext", {$, ssrContext});

	$.app.config.globalProperties.env = {
		telegramBotLink: import.meta.env.VITE_telegramBotLink,
		expzAppLink: import.meta.env.VITE_expzAppLink,
		backApiUrl: import.meta.env.VITE_BACK_API_URL,
		mainGoogleTagId: import.meta.env.VITE_mainGoogleTagId,
		staticApiUrl: import.meta.env.VITE_STATIC_API_URL,
		staticApiFetch: import.meta.env.VITE_STATIC_API_FETCH === "true",
		isSentryEnabled: import.meta.env.VITE_SENTRY_ENABLED === "true",
	};
	$.app.provide("telegramLink", import.meta.env.VITE_telegramBotLink);

	for (const [key, value] of Object.entries($.app.config.globalProperties.env)) {
		$.app.provide(key, value);
	}

	$.pageState = createPageState($, ssrContext);

	$.router = createAppRouter($, ssrContext);
	$.head = createHead();

	console.log("$.app.config", $.app);

	if ($.app.config.globalProperties.env.isSentryEnabled) {
		Sentry.init({
			environment: import.meta.env.VITE_APP_MODE,
			release: import.meta.env.VITE_APP_VERSION,
			dsn: import.meta.env.VITE_SENTRY_DSN,

			tracePropagationTargets: [
				"localhost",
				import.meta.env.VITE_SENTRY_HOST,
				import.meta.env.VITE_BACK_API_URL,
				import.meta.env.VITE_STATIC_API_URL,
			],
			integrations: [
				Sentry.replayIntegration({
					maskAllText: import.meta.env?.VITE_APP_MODE === "prod",
					stickySession: true,
				}),
			],
			// Performance Monitoring
			enableTracing: import.meta.env?.VITE_APP_MODE !== "prod",
			tracesSampleRate: import.meta.env?.VITE_APP_MODE === "prod" ? 0 : 1,
			replaysSessionSampleRate: import.meta.env?.VITE_APP_MODE === "prod" ? 0 : 0.1,
			replaysOnErrorSampleRate: import.meta.env?.VITE_APP_MODE === "prod" ? 0 : 1,
			ignoreErrors: [
				"NetworkError when attempting to fetch resource",
				"Load failed",
				"Failed to fetch",
				"'text/html' is not a valid JavaScript MIME type",
				"Importing a module script failed",
			],
			allowUrls: [window.location.origin],
			beforeSend (event, hint) {
				const error = hint.originalException;
				if (
						  error &&
						  error.message &&
						  (error.message.match(/Failed to fetch/i)
							|| error.message.match(/Load failed/i)
							|| error.message.match(/TelegramGameProxy/i)
							|| error.message.match(/Unable to preload CSS/i))
				) {
					return null;
				}
				return event;
			},
		});
	}

	const langCtrl = await setupI18n($, {
		locale: $.locale,
		legacy: false,
	});
	$.app.use(langCtrl.i18n);
	$.app.use($.router);
	$.app.use($.pageState);
	$.app.use($.head);

	registerComponents($.app);

	if (typeof window === "object") {
		window.app = $.app;
	}


	$.app.use(VueGtag, {
		config: {id: import.meta.env.VITE_mainGoogleTagId},
	});
	console.log(getRandomCat());
	return $;
}

export async function render ($ = {}) {
	$.cookies = new Cookie();
	$.ua = UAParser(navigator.userAgent);
	if ($.cookies.get("locale") == "ua" || $.cookies.get("locale") == "ru") {
		$.cookies.set("locale", "uk");
	}
	$.locale = $.cookies.get("locale") || document.documentElement.lang;

	$ = await createApp($);

	initInterceptors($);
	// $.store.replaceState($?.context?.initialState);
	$.pageState.$applyInitial($?.context?.initialState?.page);

	// set the router to the desired URL before rendering
	$.initialRender = true;
	if ($.route) {
		$.router.push($.route);
	}
	// wait until router is ready before mounting to ensure hydration match
	await $.router.isReady();

	$.app.mount("#app");
	$.initialRender = false;
	window.$ = $;
	return $;
}

render({
	context: {
		isClient: true,
		isSSR: false,
		initialState: window.__INITIAL_STATE__,
	},
	config: window.__APP_CONFIG__,
});
